import './index.scss';

function GlobalFooter() {
  return (
    <div id="globalFooter" className="globalFooter">
      Hello global footer
    </div>
  );
}

export default GlobalFooter;
