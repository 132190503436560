// React
import React from 'react';
import Error from '@components/pages/Error';

// Utilities
import { filterPost } from '@components/utilities/filterPost/filterPost';

// App
import PageMetas from '@components/modules/page-metas';

function Portfolio(props) {
  // Page helper
  const postType = window.location.pathname.split('/')[1];

  // Page props
  const { PageData } = props;
  const { AcfData } = props;
  const { PortfolioData } = props;

  // Error page check
  if (PortfolioData.length === 0 || (PortfolioData.data && PortfolioData.data.status === 404)) {
    return (
      <Error AcfData={AcfData} />
    );
  }

  // Filter results by slug or ID to get data for page.
  const portfolioPageData = filterPost(PageData, postType);

  // Update page meta
  const meta = {
    url: window.location.href,
    title: `${postType.charAt(0).toUpperCase() + postType.slice(1)} page`,
    description: `${postType.charAt(0).toUpperCase() + postType.slice(1)} page description goes here`,
    image: AcfData && AcfData.share_image
      ? AcfData.share_image
      : ''
  }

  return (
    <div id="PortfolioComponent" className="component-page--portfolio">
      <>
        <PageMetas meta={meta}/>
        <p>
          <span>{AcfData.site_email}</span> |{" "}
          <span>{AcfData.site_number}</span>
        </p>

        {portfolioPageData.map(postData => 
          <div key={postData.id} className="gst-portfolio-pages">
            <h1>{postData.title.rendered}: {postData.id}</h1>
            <pre style={{ textAlign: 'left' }}>{JSON.stringify(postData, null, 2) }</pre>
          </div>
        )}

        <hr />
        {PortfolioData.map(postData => 
          <div key={postData.id} className="gst-portfolio-item">
            <h1>{postData.title.rendered}: {postData.id}</h1>
            <pre style={{ textAlign: 'left' }}>{JSON.stringify(postData, null, 2) }</pre>
          </div>
        )}
      </>
    </div>
  );
}

export default Portfolio;