// React
import React from 'react';

// Utilities
import { useFetch, WpRestUrl  } from '@components/utilities/useFetch/useFetch';
import { filterPost } from '@components/utilities/filterPost/filterPost';

// App
import PageMetas from '@components/modules/page-metas';

function About(props) {
  // Fetch data
  const postType = window.location.pathname.split('/')[1];

  // Page props
  const { PageData } = props;
  const { AcfData } = props;

  // Filter results by slug or ID to get data for page.
  const aboutPageData = filterPost(PageData, postType);

  // Fetch post data
	const url = `${WpRestUrl}/posts`;
  const { statusPosts, dataPosts, errorPosts } = useFetch('Posts', `${url}`);
  const posts = 'posts' in dataPosts
    ? dataPosts.posts
    : {};

  // Update page meta
  const meta = {
    url: window.location.href,
    title: `${postType.charAt(0).toUpperCase() + postType.slice(1)}`,
    description: 'This page is about myself',
    image: AcfData && AcfData.share_image
      ? AcfData.share_image
      : ''
  }

  // Render
  return (
    <div id="AboutComponent" className="component-page--about">
      
      Hello about page

      <div>
				{statusPosts === 'error' && <div>{errorPosts}</div>}
				{statusPosts === 'fetching' && <div className="loading"></div>}
        {statusPosts === 'fetched' && (
          <>
            <PageMetas meta={meta} />

            {aboutPageData.map(postData => 
              <div key={postData.id} className="gst-about-page">
                <h1>{postData.title.rendered}: {postData.id}</h1>
                <pre style={{ textAlign: 'left' }}>{JSON.stringify(postData, null, 2) }</pre>
              </div>
            )}

            <img src={AcfData.share_image} alt="This is some alt text" />
            <p>
              <span>{AcfData.site_email}</span> |{" "}
              <span>{AcfData.site_number}</span>
            </p>
            <pre style={{ textAlign: 'left' }}>{JSON.stringify(posts, null, 2) }</pre>
					</>
        )}
      </div>
    </div>
  );
}

export default About;
