// React
import React from 'react';

// App
import PageMetas from '@components/modules/page-metas';

function Error(props) {
  // Page props
  const { AcfData } = props;

  // Update page meta
  const meta = {
    url: window.location.href,
    title: `Error page`,
    description: `Error page description goes here`,
    image: AcfData && AcfData.share_image
      ? AcfData.share_image
      : ''
  }

  return (
    <div id="ErrorComponent" className="component-page--error">
      <PageMetas meta={meta}/>
      <p>
        <span>{AcfData.site_email}</span> |{" "}
        <span>{AcfData.site_number}</span>
      </p>
      Error! Page not found!
    </div>
  );
}

export default Error;
