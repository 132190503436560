// React
import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import './index.scss';

function GlobalHeader(props) {
  // Component props
  const { PortfolioData } = props;

  // Render
  return (
    <div id="globalHeader" className="globalHeader">
      <nav>
        <>
          <Link to="/">Home</Link> |{" "}
          <Link to="/portfolio">Portfolio page</Link> |{" "}
          {PortfolioData && Array.isArray(PortfolioData) && PortfolioData.map(linkData =>
            <React.Fragment key={linkData.slug}>
              <Link to={`/${linkData.type}/${linkData.slug}`}>{linkData.title.rendered}</Link> |{" "}
            </React.Fragment>
          )}
          <Link to="/about">About</Link>
        </>
      </nav>
      <div>Hello global header</div>
    </div>
  );
}

export default GlobalHeader;
