// App
import { Helmet, HelmetProvider } from 'react-helmet-async';

/**
 * Retrieve post data from rest API
 * @param {object from URL} params Data pulled from URL
 * @param {object} json Rest API result
 * @param {*} enableLog Option to log data to console once pulled
 * @returns 
 */
const pageMetas = (props) => {
  const { meta } = props;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{meta && meta.title || ''}</title>

          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content='website' />
          <meta property="og:title" content={meta && meta.title || ''} />
          <meta property="og:description" content={meta && meta.description || ''} />
          <meta property="og:image" content={meta && meta.image || ''} />

          <meta property="twitter:card" content='summary' />
          <meta property="twitter:title" content={meta && meta.title || ''} />
          <meta property="twitter:description" content={meta && meta.description || ''} />
          <meta property="twitter:image" content={meta && meta.image || ''} />         
        </Helmet>
      </HelmetProvider>
    </>
  )
}

export default pageMetas;