/**
 * Filters data to return WP post based on slug or ID
 * @param {*} data Array of post data to filter
 * @param {*} urlProps Slug or ID to filter by
 * @returns 
 */
export const filterPost = (data, urlProps) => {
  if (!data || !Array.isArray(data)) return [];

  return data.filter((postFilter) => {
    const props = (typeof urlProps === 'string')
      ? urlProps
      : urlProps.slug || urlProps.id

    return (props === postFilter.slug || Number(props) === Number(postFilter.id))
    || (props === postFilter.slug || Number(props) === Number(postFilter.id));  
  });
}
