// React
import React from 'react';
import { useParams } from 'react-router-dom';
import Error from '@components/pages/Error';

// Utilities
import { filterPost } from '@components/utilities/filterPost/filterPost';

// App
import PageMetas from '@components/modules/page-metas';

function PortfolioSingle(props) {
  // Page helper
  const postType = window.location.pathname.split('/')[1];

  // Page props
  const urlProps = useParams();
  const { AcfData } = props;
  const { PortfolioData } = props;

  // Filter results by slug or ID to get data for page.
  const pageData = filterPost(PortfolioData, urlProps);

  // Error page check  
  if (pageData.length === 0) {
    return (
      <Error AcfData={AcfData} />
    );
  }

  // Update page meta
  const meta = {
    url: window.location.href,
    title: pageData.length > 0 && pageData[0].title 
      ? `${pageData[0].title.rendered}`
      : `${postType.charAt(0).toUpperCase() + postType.slice(1)}`,
    description: pageData.length > 0 && pageData[0].content 
      ? `${pageData[0].content.rendered.replace(/<(.|\n)*?>/g, '').trim()}`
      : AcfData.site_description.replace(/<(.|\n)*?>/g, '').trim(),
    image: AcfData && AcfData.share_image
      ? AcfData.share_image
      : ''
  }

  // Render
  return (
    <div id="PortfolioSingleComponent" className="component-single--portfolio">
      <div>
        <p>
          <span>{AcfData.site_email}</span> |{" "}
          <span>{AcfData.site_number}</span>
        </p>

        {pageData.map(postData => 
          <div key={postData.id} className="gst-portfolio-item">
            <PageMetas meta={meta}/>
            <h1>{postData.title.rendered}: {postData.id}</h1>
            <pre style={{ textAlign: 'left' }}>{JSON.stringify(postData, null, 2) }</pre>
          </div>
        )}
      </div>
    </div>
  );
}

export default PortfolioSingle;