// React
import React from 'react';

// App
import PageMetas from '@components/modules/page-metas';

function Home(props) {
  // Page props
  const { AcfData } = props;

  // Update page meta
  const meta = {
    url: window.location.href,
    title: `Home page`,
    description: `Home page description goes here`,
    image: AcfData && AcfData.share_image
      ? AcfData.share_image
      : ''
  }

  return (
    <div id="HomeComponent" className="component-page--home">
      <PageMetas meta={meta}/>

      <p>
        <span>{AcfData.site_email}</span> |{" "}
        <span>{AcfData.site_number}</span>
      </p>

      Hello home page
    </div>
  );
}

export default Home;
